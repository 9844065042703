<template>
  <div
    v-if="data"
    class="h-[calc(100vh_-_325px)] xl:h-[calc(100vh_-_335px)] 2xl:h-[calc(100vh_-_365px)] p-8 xl:mx-2 mt-2 overflow-auto whitespace-pre-line "
  >
    <iframe ref="iframe" class="w-full h-full"></iframe>
  </div>
  <EmptyContentComponent
    v-else
    type="HTML"
  />
</template>
<script>

import EmptyContentComponent from '@/components/include/EmptyContentComponent.vue'

export default {
  name: 'HtmlTab',
  components: { EmptyContentComponent },
  props: {
    data: {
      type: String,
      default: ''
    }
  },
  mounted () {
    this.updateIframeContent()
  },
  methods: {
    updateIframeContent () {
      if (!this.data) return
      const iframe = this.$refs.iframe
      const doc = iframe.contentDocument || iframe.contentWindow.document
      doc.open()
      doc.write(this.data)
      doc.close()
    }
  }
}
</script>
