<template>
  <nav class="mt-4 w-[210px] flex flex-col justify-start items-start text-[#8E95BD] text-[18px] font-futura">
<!--  System Status  -->
    <a
      href="https://monitor.mailinator.com/status/mailinator"
      class="mx-1 mb-4 px-2 hover:text-white"
    >
      System Status
    </a>
<!--  Terms & Conditions  -->
    <a
      href="https://www.mailinator.com/terms-of-use/"
      class="mx-1 mb-4 px-2 hover:text-white"
    >
      Terms & Conditions
    </a>
<!--  Privacy Policy  -->
    <a
      href="https://www.mailinator.com/privacy-policy/"
      class="mx-1 mb-4 px-2 hover:text-white"
    >
      Privacy Policy
    </a>
<!--  Accessibility  -->
    <a
      href="https://www.mailinator.com/accessibility/"
      class="mx-1 mb-4 px-2 hover:text-white"
    >
      Accessibility
    </a>
<!--  Security  -->
    <a
      href="https://www.mailinator.com/security-details/"
      class="mx-1 mb-4 px-2 hover:text-white"
    >
      Security
    </a>
  </nav>
</template>

<script>
export default {
  name: 'NavigationListComponent'
}
</script>
