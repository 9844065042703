<template>
  <PrivateMailLayout>
    <LoaderComponent v-if="isLoading"/>
    <PrivateMailComponent
      v-else
      :mail-data="data"
    />
  </PrivateMailLayout>
</template>

<script>

import PrivateMailLayout from '@/layouts/PrivateMailLayout.vue'
import LoaderComponent from '@/components/include/LoaderComponent.vue'
import PrivateMessagesService from '@/http/services/private/privateMessages.service'
import PrivateMailComponent from '@/components/private/mailPage/PrivateMailComponent.vue'

export default {
  name: 'MailView',
  components: {
    PrivateMailComponent,
    PrivateMailLayout,
    LoaderComponent
  },
  data () {
    return {
      data: {},
      isLoading: false
    }
  },
  async mounted () {
    const id = this.$route.query.msgid
    if (!id) this.$router.push({ name: 'PrivateMailInbox' })

    this.isLoading = true
    this.data = await PrivateMessagesService.fetchPrivateMessage(id)
    this.isLoading = false
  }
}
</script>
