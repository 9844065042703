<template>
  <div class="w-full h-[calc(100vh_-_345px)] flex justify-center items-center">
    <div class="w-[300px] h-[300px] bg-[#F2F2F3] flex flex-col justify-center items-center rounded-full">
      <svg width="76" height="96" viewBox="0 0 76 96" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M37.9793 59.6665L38 59.6669L38.0208 59.6665C41.5672 59.6034 45.0903 60.2534 48.3807 61.5779C51.666 62.9003 54.6527 64.8682 57.164 67.365C57.5808 67.8077 57.8084 68.3958 57.798 69.0041C57.7876 69.6171 57.5363 70.2013 57.0985 70.6305C56.6607 71.0597 56.0716 71.2993 55.4586 71.2976C54.8455 71.2959 54.2577 71.053 53.8223 70.6214L53.8196 70.6188C51.7333 68.5641 49.2559 66.9491 46.534 65.8695C43.8202 64.7931 40.9186 64.2708 38 64.3331C35.0814 64.2708 32.1798 64.7931 29.4661 65.8695C26.7442 66.9491 24.2667 68.5641 22.1804 70.6188L22.1671 70.6318L22.1543 70.6452C21.941 70.8691 21.6854 71.0484 21.4022 71.1726C21.119 71.2968 20.8139 71.3634 20.5048 71.3687C20.1956 71.374 19.8885 71.3177 19.6012 71.2033C19.314 71.0888 19.0523 70.9183 18.8315 70.7019C18.6107 70.4854 18.4351 70.2272 18.315 69.9423C18.1948 69.6573 18.1325 69.3514 18.1317 69.0421C18.1308 68.7329 18.1914 68.4266 18.31 68.141C18.4286 67.8554 18.6027 67.5963 18.8223 67.3786L18.8241 67.3768C21.3379 64.8744 24.3288 62.9024 27.6193 61.5779C30.9098 60.2534 34.4328 59.6034 37.9793 59.6665Z" fill="#2979FF"/>
        <path d="M55.2087 48C55.2087 49.7719 53.7722 51.2083 52.0003 51.2083C50.2284 51.2083 48.792 49.7719 48.792 48C48.792 46.2281 50.2284 44.7917 52.0003 44.7917C53.7722 44.7917 55.2087 46.2281 55.2087 48Z" fill="#2979FF"/>
        <path d="M27.2087 48C27.2087 49.7719 25.7722 51.2083 24.0003 51.2083C22.2284 51.2083 20.792 49.7719 20.792 48C20.792 46.2281 22.2284 44.7917 24.0003 44.7917C25.7722 44.7917 27.2087 46.2281 27.2087 48Z" fill="#2979FF"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M50.1499 0.850458L74.6499 25.3505C75.0874 25.7879 75.3335 26.3816 75.3337 27.0002V86.5C75.3337 88.9754 74.3503 91.3493 72.6 93.0997C70.8497 94.85 68.4757 95.8333 66.0003 95.8333H10.0003C7.52497 95.8333 5.151 94.85 3.40066 93.0997C1.65032 91.3493 0.666992 88.9754 0.666992 86.5V9.5C0.666992 7.02465 1.65032 4.65068 3.40066 2.90034C5.151 1.15 7.52497 0.166667 10.0003 0.166667H48.5003C49.119 0.166865 49.7124 0.412903 50.1499 0.850458ZM67.3679 24.6667L50.8337 8.13242V20C50.8337 21.2377 51.3253 22.4247 52.2005 23.2998C53.0757 24.175 54.2626 24.6667 55.5003 24.6667H67.3679ZM70.667 86.5V29.3333H55.5003C53.025 29.3333 50.651 28.35 48.9007 26.5997C47.1503 24.8493 46.167 22.4754 46.167 20V4.83333H10.0003C8.76265 4.83333 7.57566 5.325 6.7005 6.20017C5.82533 7.07534 5.33366 8.26232 5.33366 9.5V86.5C5.33366 87.7377 5.82532 88.9247 6.7005 89.7998C7.57567 90.675 8.76265 91.1667 10.0003 91.1667H66.0003C67.238 91.1667 68.425 90.675 69.3002 89.7998C70.1753 88.9247 70.667 87.7377 70.667 86.5Z" fill="#2979FF"/>
      </svg>
      <p class="mt-4 font-futura text-[24px] font-bold">No {{ type }} Yet</p>
    </div>
  </div>
</template>
<script>

export default {
  name: 'EmptyContentComponent',
  props: {
    type: {
      type: String,
      default: 'content'
    }
  }
}
</script>
