<template>
  <header class="col-span-12 flex flex-row flex-wrap justify-between items-center px-4 font-futura text-[18px]">
    <svg role="img" width="160" height="18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#a)">
        <path
          d="M51.672 1.594h-3.716v16.352h2.867V6.204l3.771 10.241h2.071L60.49 6.192v11.752h2.92V1.594h-3.717l-4.01 11.2-4.01-11.2Z"
          fill="#fff"/>
        <path fill-rule="evenodd" clip-rule="evenodd"
              d="M73.391 10.045c-5.362.552-7.646 1.9-7.646 4.6 0 2.151 1.593 3.6 3.875 3.6 1.54 0 2.813-.7 3.983-1.65v1.351h2.922v-7.275c0-3.424-1.646-5.325-4.886-5.325-2.071 0-3.928.748-5.363 1.6l1.115 1.949c1.169-.65 2.39-1.15 3.664-1.15 1.753 0 2.284 1.05 2.336 2.3Zm.003 2.001v2.6c-.956.85-1.753 1.25-2.816 1.25-1.062 0-1.805-.4-1.805-1.45 0-1.25 1.063-2 4.62-2.4Z"
              fill="#fff"/>
        <path
          d="M81.728.194c-1.114 0-1.884.676-1.884 1.676 0 1 .77 1.676 1.885 1.676 1.114 0 1.884-.675 1.884-1.676 0-1-.77-1.676-1.885-1.676ZM83.298 5.645h-3.16v12.301h3.16v-12.3ZM86.98 1.197V14.72c0 2.177.821 3.525 3.029 3.525.742 0 1.273-.1 1.646-.275l-.373-2.174c-.213.049-.32.049-.479.049-.318 0-.663-.25-.663-.975V0l-3.16 1.197ZM95.932.194c-1.114 0-1.884.676-1.884 1.676 0 1 .772 1.676 1.884 1.676 1.115 0 1.885-.675 1.885-1.676 0-1-.77-1.676-1.885-1.676ZM97.501 5.645h-3.16v12.301h3.16v-12.3ZM104.078 5.645h-2.897v12.301h3.161v-8.55c1.037-1.001 1.78-1.502 2.922-1.502 1.38 0 1.991.725 1.991 2.726v7.326h3.136v-7.7c0-3.1-1.222-4.9-4.037-4.9-1.78 0-3.106.9-4.276 1.95v-1.65Z"
          fill="#fff"/>
        <path fill-rule="evenodd" clip-rule="evenodd"
              d="M122.615 10.045c-5.363.552-7.647 1.9-7.647 4.6 0 2.151 1.594 3.6 3.875 3.6 1.54 0 2.813-.7 3.983-1.65v1.351h2.922v-7.275c0-3.424-1.646-5.325-4.886-5.325-2.071 0-3.928.748-5.363 1.6l1.115 1.949c1.169-.65 2.391-1.15 3.664-1.15 1.753 0 2.284 1.05 2.337 2.3Zm0 2.001v2.6c-.956.85-1.753 1.25-2.816 1.25-1.062 0-1.805-.4-1.805-1.45 0-1.25 1.063-2 4.621-2.4Z"
              fill="#fff"/>
        <path
          d="M129.412 7.995v5.776c0 2.674 1.115 4.474 4.169 4.474 1.063 0 2.018-.226 2.709-.526l-.559-2.075c-.449.175-.901.25-1.353.25-1.222 0-1.832-.7-1.832-2.125V7.992h3.319V5.643h-3.319V1.82l-2.975 1.197v2.63h-2.018v2.349h1.859Z"
          fill="#fff"/>
        <path fill-rule="evenodd" clip-rule="evenodd"
              d="M143.539 5.346c-3.265 0-6.239 2.375-6.239 6.476 0 4.074 2.972 6.424 6.239 6.426 3.24 0 6.215-2.352 6.215-6.426 0-4.1-2.975-6.476-6.215-6.476Zm-.002 2.424c1.887 0 3.001 1.602 3.001 4.052 0 2.424-1.114 4.025-3.001 4.025-1.885 0-2.999-1.601-2.999-4.025 0-2.453 1.114-4.052 2.999-4.052Z"
              fill="#fff"/>
        <path
          d="M154.779 5.645h-2.895v12.301h3.158v-7.45c.77-1.875 2.018-2.525 3.054-2.525.531 0 .851.05 1.249.15l.638-2.525c-.4-.15-.825-.25-1.488-.25-1.38 0-2.76.9-3.716 2.5v-2.2ZM16 3.904l12.116 11.408L40.232 3.906v14.04H30.98l1.949-1.837h5.35v-7.77l-10.164 9.57L17.95 8.336v7.773h5.35l1.95 1.837H16V3.904Z"
          fill="#fff"/>
        <path d="M28.116 12.9 16.109 1.593H40.12L28.116 12.9Zm-7.297-9.468 7.297 6.87 7.297-6.87H20.819Z"
              fill="url(#b)"/>
      </g>
      <defs>
        <linearGradient id="b" x1="22.27" y1="7.205" x2="29.874" y2="-1.026" gradientUnits="userSpaceOnUse">
          <stop stop-color="#2FC0D1"/>
          <stop offset="1" stop-color="#A9D9BC"/>
        </linearGradient>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h160v18H0z"/>
        </clipPath>
      </defs>
    </svg>
    <button
      aria-label='buegre-button'
      class="flex justify-center items-center mt-3 mr-4 md:mt-0 lg:hidden"
      @click="$emit('toggle-navbar')"
    >
      <svg
        role="img"
        class="w-[25px] h-[25px]"
        viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 13.813C0 13.1534 0.510195 12.626 1.13082 12.626H10C10.6206 12.626 11.1308 13.1534 11.1308 13.813C11.1308 14.4726 10.6206 15 10 15H1.13082C0.510195 15 0 14.4726 0 13.813Z"
          fill="white"/>
        <path
          d="M0 7.37116C0 6.71159 0.510195 6.18415 1.13082 6.18415H18.8692C19.4898 6.18415 20 6.71159 20 7.37116C20 8.03074 19.4898 8.55818 18.8692 8.55818H1.13082C0.510195 8.55818 0 8.03074 0 7.37116Z"
          fill="white"/>
        <path
          d="M0 1.18701C0 0.527438 0.510195 0 1.13082 0H18.8692C19.4898 0 20 0.527438 20 1.18701C20 1.84659 19.4898 2.37403 18.8692 2.37403H1.13082C0.510195 2.37403 0 1.84659 0 1.18701Z"
          fill="white"/>
      </svg>
    </button>
    <nav class="hidden lg:flex font-[18px] text-[#8E95BD]">
      <!--    Home    -->
      <a
        href="https://www.mailinator.com/index.jsp"
        class="py-2 px-3 mx-2 rounded-lg hover:bg-[#3A4062] hover:text-white"
      >
        Home
      </a>
      <!--    Email    -->
      <a
        class="py-2 px-3 mx-2 rounded-lg bg-[#3A4062] text-white cursor-pointer"
      >
        Email
      </a>
      <!--    Documentation    -->
      <a
        href="https://www.mailinator.com/docs/index.html#mailinator"
        class="py-2 px-3 mx-2 rounded-lg hover:bg-[#3A4062] hover:text-white"
      >
        Documentation
      </a>
      <!--    Pricing    -->
      <a
        v-if="isPublic"
        href="https://www.mailinator.com/pricing"
        class="py-2 px-3 mx-2 rounded-lg hover:bg-[#3A4062] hover:text-white"
      >
        Pricing
      </a>
      <!--    FAQ    -->
      <a
        href="https://www.mailinator.com/mailinator-faqs/"
        class="py-2 px-3 mx-2 rounded-lg hover:bg-[#3A4062] hover:text-white"
      >
        FAQ
      </a>
      <!--    Mail settings    -->
      <router-link
        v-if="!isPublic"
        to="/" class="mx-2 px-3 py-2 flex justify-center items-center rounded-lg hover:text-white hover:bg-[#3A4062]"
      >
        <span>slavavolkovelance@gmail.com</span>
      </router-link>
      <!--    Logout    -->
      <button
        aria-label="logout-button"
        v-if="!isPublic"
        @click="$emit('open-logout-confirm')"
        class="ml-2 py-2 px-3 flex justify-center items-center rounded-lg hover:bg-[#3A4062] hover:text-white"
      >
        Logout
      </button>
      <!--    Login    -->
      <router-link
        aria-label="login-button"
        v-if="isPublic"
        :to="{ name: 'EmailLogin' }"
        class="ml-2 py-2 px-6 flex justify-center items-center rounded-lg bg-white text-black"
      >
        Login
      </router-link>
    </nav>
  </header>
</template>
<script>

export default {
  name: 'HeaderComponent',
  props: {
    isPublic: {
      type: Boolean,
      default: false
    }
  }
}
</script>
