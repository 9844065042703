<template>
  <PublicMailLayout>
    <LoaderComponent v-if="isLoading"/>
    <PublicMailComponent
      v-else
      tittle="Public Messages"
      :mail-data="data"
    />
  </PublicMailLayout>
</template>

<script>

import PublicMailComponent from '@/components/public/mailPage/PublicMailComponent.vue'
import LoaderComponent from '@/components/include/LoaderComponent.vue'
import PublicMailLayout from '@/layouts/PublicMailLayout.vue'
import PublicMessagesService from '@/http/services/public/publicMessages.service'

export default {
  name: 'PublicMailView',
  components: {
    PublicMailLayout,
    PublicMailComponent,
    LoaderComponent
  },
  data () {
    return {
      data: {},
      isLoading: false
    }
  },
  async mounted () {
    const id = this.$route.query.msgid
    if (!id) this.$router.push({ name: 'PublicMailInbox' })

    this.isLoading = true
    this.data = await PublicMessagesService.fetchPublicMessage(id)
    this.isLoading = false
  }
}
</script>
