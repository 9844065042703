<template>
  <AuthLayout
    tittle="SSO Login"
  >
    <SsoLoginComponent/>
  </AuthLayout>
</template>

<script>

import AuthLayout from '@/layouts/AuthLayout.vue'
import SsoLoginComponent from '@/components/auth/SsoLoginComponent.vue'

export default {
  name: 'SsoLoginVIew',
  components: {
    AuthLayout,
    SsoLoginComponent
  }
}
</script>
