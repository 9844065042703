<template>
  <div class="w-full px-2 md:px-4 mt-4 flex flex-col md:flex-row flex-wrap justify-between items-start md:items-center">
    <div class="mx-2 mb-2 flex flex-col justify-start items-start">
      <span class="text-[18px] font-semibold">From</span>
      <span class="text-[18px] mt-1">{{mailData.fromfull ? mailData.fromfull : '-'}}</span>
    </div>
    <div class="mx-2 mb-2 flex flex-col justify-start items-start">
      <span class="text-[18px] font-semibold">Sending IP</span>
      <span class="text-[18px] mt-1">{{mailData.ip ? mailData.ip : '-'}}</span>
    </div>
    <div class="mx-2 mb-2 flex flex-col justify-start items-start">
      <span class="text-[18px] font-semibold">Received</span>
      <span class="text-[18px] mt-1">{{mailData.headers?.date ? mailData.headers?.date : '-'}}</span>
    </div>
  </div>
</template>
<script>

export default {
  name: 'MailInfoComponent',
  props: {
    mailData: {
      type: Object,
      default: () => {}
    }
  }
}
</script>
