<template>
  <div class="grid grid-cols-12 grid-rows-12 min-h-screen bg-gradient-to-b from-[#2A2F48] to-[#141622] tracking-wide">
    <HeaderComponent
      @toggle-navbar="toggleNavbar"
      @open-logout-confirm="isOpen = true"
    />
    <div class="col-span-12 row-span-12 flex">
      <NavbarMobileScreenComponent
        :is-navbar-open="navbarState"
        @toggle-navbar="toggleNavbar"
        @open-logout-confirm="isOpen = true"
      />
      <NavbarComponent/>
      <main class="w-full lg:w-[calc(100vw_-_250px)] h-full px-2 pb-2 md:px-4 md:pb-4 lg:pl-0 lg:pr-1">
        <slot></slot>
      </main>
    </div>
  </div>
  <ModalComponent
    :is-open="isOpen"
    @on-close="() => isOpen = false"
    @on-confirm="logout"
  >
    <template v-slot:head>
      Confirm logout
    </template>
    <template v-slot:content>
      Are you sure you want to logout from Private Mailinator Inbox?
    </template>
  </ModalComponent>
</template>
<script>

import HeaderComponent from '@/components/include/HeaderComponent.vue'
import NavbarComponent from '@/components/include/navbar/laptopScreen/NavbarComponent.vue'
import NavbarMobileScreenComponent from '@/components/include/navbar/mobileScreen/NavbarMobileScreenComponent.vue'
import ModalComponent from '@/components/include/ModalComponent.vue'

export default {
  name: 'PrivateMailLayout',
  components: {
    ModalComponent,
    HeaderComponent,
    NavbarComponent,
    NavbarMobileScreenComponent
  },
  data () {
    return {
      isOpen: false,
      navbarState: false
    }
  },
  methods: {
    toggleNavbar () {
      this.navbarState = !this.navbarState
    },
    logout () {
      this.isOpen = false
      //   TODO: add logout logic
    }
  }
}
</script>
