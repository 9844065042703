<template>
  <AuthLayout
    tittle="Reset Your Password"
  >
    <ResetPasswordComponent/>
  </AuthLayout>
</template>

<script>

import AuthLayout from '@/layouts/AuthLayout.vue'
import ResetPasswordComponent from '@/components/auth/ResetPasswordComponent.vue'

export default {
  name: 'ResetPasswordView',
  components: {
    AuthLayout,
    ResetPasswordComponent
  }
}
</script>
