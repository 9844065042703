<template>
  <div
    v-if="isLoading"
    class="h-[calc(100vh_-_325px)] xl:h-[calc(100vh_-_335px)] 2xl:h-[calc(100vh_-_365px)] p-8 xl:mx-2 mt-2 overflow-auto whitespace-pre-line flex justify-center items-center"
  >
    <half-circle-spinner :animation-duration="1000" :size="60" color="#262A41" />
  </div>
  <div
    v-if="rawData && !isLoading"
    class="h-[calc(100vh_-_325px)] xl:h-[calc(100vh_-_333px)] 2xl:h-[calc(100vh_-_366px)] p-8 mx-2 mt-2 overflow-auto whitespace-pre-line"
  >
    <pre>{{ rawData }}</pre>
  </div>
  <EmptyContentComponent
    v-else-if="!rawData && !isLoading"
    type="Raw"
  />
</template>
<script>
import EmptyContentComponent from '@/components/include/EmptyContentComponent.vue'
import { HalfCircleSpinner } from 'epic-spinners'
import { jsonToPlainText } from 'json-to-plain-text'
// import PrivateMessagesService from '@/http/services/messages.service'

const options = {
  color: true,
  spacing: true,
  seperator: ':',
  squareBracketsForArray: false,
  doubleQuotesForKeys: false,
  doubleQuotesForValues: false
}
export default {
  name: 'RawTab',
  components: {
    EmptyContentComponent,
    HalfCircleSpinner
  },
  props: {
    data: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      isLoading: false,
      rawData: ''
    }
  },
  mounted () {
    this.getRawData()
  },
  methods: {
    async getRawData () {
      const id = this.$route.query.msgid
      if (!id) this.$router.push({ name: 'PrivateMailInbox' })
      this.isLoading = true
      this.rawData = jsonToPlainText(this.data, options)
      // this.data = await PrivateMessagesService.fetchPrivateMessageDetail(id, 'raw')
      this.isLoading = false
    }
  }
}
</script>
