// const domain = process.env.VUE_APP_WS_DOMAIN
const domain = 'wss://www.mailinator.com/ws'
let socket = null
let messageCallback = null

let isWsPaused = false
let wsDomain = ''

export function setWsPause (val) {
  isWsPaused = val
}

export function setDomain (newDomain) {
  wsDomain = newDomain
}

export async function connectWebSocket (onMessage) {
  console.log(wsDomain)
  return new Promise((resolve, reject) => {
    socket = new WebSocket(domain + '/fetchprivate')
    messageCallback = onMessage

    socket.addEventListener('open', (event) => {
      resolve()
    })

    socket.addEventListener('message', (event) => {
      if (messageCallback && !isWsPaused) {
        const data = JSON.parse(event.data)
        messageCallback(data)
      }
    })

    socket.addEventListener('error', (event) => {
      console.error('WebSocket error:', event)
      reject(event)
    })
  })
}

export async function createRoom (roomName) {
  if (socket && socket.readyState === WebSocket.OPEN) {
    const message = JSON.stringify({
      domain: 'slava1.testinator.com',
      cmd: 'sub',
      channel: roomName === '' ? '*' : roomName
    })
    socket.send(message)
  } else {
    console.error('WebSocket is not connected.')
  }
}

export function disconnectWebSocket () {
  if (socket) {
    socket.close()
  }
}
