<template>
  <div class="mt-2 flex flex-col md:flex-row flex-wrap justify-between items-start md:items-center text-futura">
    <!--   mail input     -->
    <input
      placeholder="Add Inbox"
      class="w-[260px] h-[40px] py-1 px-4 border-2 rounded-lg appearance-none focus:outline-none placeholder:text-[#262A41]"
      v-model="inboxName"
      @change="changeInboxes($event)"
    />
    <!--   play/stop button   -->
    <button
      class="w-[46px] h-[40px] mx-auto mt-2 md:mt-0 md:mx-2 flex justify-center items-center border-2 rounded-lg"
      @click="setWsPause"
    >
      <svg
        v-if="isWebsocketsPaused"
        width="22" height="16" viewBox="-3 0 28 28" version="1.1" xmlns="http://www.w3.org/2000/svg"
      >
        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <g transform="translate(-419.000000, -571.000000)" fill="#2979FF">
            <path d="M440.415,583.554 L421.418,571.311 C420.291,570.704 419,570.767 419,572.946 L419,597.054 C419,599.046 420.385,599.36 421.418,598.689 L440.415,586.446 C441.197,585.647 441.197,584.353 440.415,583.554" id="play">
            </path>
          </g>
        </g>
      </svg>
      <svg
        v-else
        width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M0 14H4V0H0V14ZM8 0V14H12V0H8Z" fill="#2979FF"/>
      </svg>
    </button>
  </div>
</template>

<script>
import { createRoom, setWsPause } from '@/http/services/public/publicWebsocket.service'
import PublicMessagesService from '@/http/services/public/publicMessages.service'
export default {
  name: 'PublicControllersComponent',
  data () {
    return {
      inboxName: '',
      isWebsocketsPaused: false
    }
  },
  watch: {
    async $route () {
      this.inboxName = this.$route.query?.inbox
    }
  },
  mounted () {
    this.inboxName = this.$route.query?.inbox ? this.$route.query?.inbox : ''
  },
  methods: {
    async changeInboxes (event) {
      this.inboxName = event.target.value ? event.target.value : ''

      await createRoom(this.inboxName)
      this.saveInbox(this.inboxName)
      this.updateRoute(this.inboxName)
      PublicMessagesService.setInbox(this.inboxName)
    },
    setWsPause () {
      this.isWebsocketsPaused = !this.isWebsocketsPaused

      setWsPause(this.isWebsocketsPaused)
      if (!this.isWebsocketsPaused) createRoom(this.inboxName)
    },
    saveInbox (inbox) {
      if (!inbox || !inbox.length) return

      const recentInboxesList = localStorage.getItem('recent-inboxes')
        ? JSON.parse(localStorage.getItem('recent-inboxes'))
        : null
      if (recentInboxesList?.includes(inbox)) return

      if (!recentInboxesList || !recentInboxesList.length) {
        localStorage.setItem('recent-inboxes', JSON.stringify([inbox]))
      } else {
        if (recentInboxesList.length < 5) {
          recentInboxesList.push(inbox)
          localStorage.setItem('recent-inboxes', JSON.stringify(recentInboxesList))
        } else {
          recentInboxesList.shift()
          recentInboxesList.push(inbox)
          localStorage.setItem('recent-inboxes', JSON.stringify(recentInboxesList))
        }
      }
    },
    updateRoute (val) {
      this.$router.replace({
        query: {
          ...this.$route.query,
          inbox: val
        }
      })
    }
  }
}
</script>
