<template>
  <div class="hidden lg:flex flex-col justify-between items-center pb-4">
    <div class="h-full flex flex-col justify-between items-center">
      <RecentInboxes v-if="isPublic"/>
      <PrivateNavigationListComponent v-if="!isPublic"/>
      <MailinatorSetupLinkComponent v-if="!isPublic"/>
      <NavigationListComponent/>
    </div>

    <div class="w-[188px] text-[#8E95BD] text-xs font-futura">
      Copyright 2024 Manybrain, LLC.
      <br>
      All Rights Reserved.
    </div>
  </div>
</template>

<script>
import PrivateNavigationListComponent from '@/components/include/navbar/laptopScreen/PrivateNavigationListComponent.vue'
import MailinatorSetupLinkComponent from '@/components/include/navbar/laptopScreen/MailinatorSetupLinkComponent.vue'
import NavigationListComponent from '@/components/include/navbar/laptopScreen/NavigationListComponent.vue'
import RecentInboxes from '@/components/include/navbar/laptopScreen/RecentInboxes.vue'

export default {
  name: 'NavbarComponent',
  components: {
    RecentInboxes,
    PrivateNavigationListComponent,
    MailinatorSetupLinkComponent,
    NavigationListComponent
  },
  props: {
    isPublic: {
      type: Boolean,
      default: false
    }
  }
}
</script>
