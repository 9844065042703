<template>
  <AuthLayout>
    <EmailLoginComponent/>
  </AuthLayout>
</template>

<script>

import AuthLayout from '@/layouts/AuthLayout.vue'
import EmailLoginComponent from '@/components/auth/EmailLoginComponent.vue'

export default {
  name: 'EmailLoginView',
  components: {
    AuthLayout,
    EmailLoginComponent
  }
}
</script>
