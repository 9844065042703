import BaseAxiosInstance from '@/axiosConfig'

const API = {
  LOGIN_WITH_EMAIL_AND_PASS: (email, password) => `/manyauth/many_login?email=${email}&password=${password}`,
  LOGIN_WITH_SSO: (email) => `/manyauth/many_loginsso?email=${email}`,
  FORGOT_PASSWORD: (email) => `/manyauth/many_forgotpw?email=${email}`
}

const AuthService = {
  async loginWithEmailAndPass (formData, queryData) {
    return await BaseAxiosInstance.post(API.LOGIN_WITH_EMAIL_AND_PASS(queryData.email, queryData.password), formData)
  },
  async loginWithSSO (formData, queryData) {
    return await BaseAxiosInstance.post(API.LOGIN_WITH_SSO(queryData.email), formData)
  },
  async resetPassword (formData, queryData) {
    return await BaseAxiosInstance.post(API.FORGOT_PASSWORD(queryData.email), formData)
  }
}

export default AuthService
