<template>
  <transition name="fade">
    <div v-if="isOpen">
      <div
        class="w-full h-full fixed left-0 top-0 bottom-0 right-0 flex justify-center items-center overflow-hidden bg-black-rgba z-10 font-futura"
      >
        <div
          class="w-full sm:max-w-[520px] pt-4 mx-8 rounded-lg bg-white opacity-1 z-20"
          :class=" large ? 'sm:max-w-[820px]' : 'sm:max-w-[520px]'"
        >
<!--    Head    -->
          <div class="w-full px-6 flex justify-between items-center">
            <h2 class="text-[#262A41] text-[20px] text-futura font-bold">
              <slot name="head"></slot>
            </h2>
            <button
              @click="$emit('on-close')"
            >
              <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13.2997 0.709727C12.9097 0.319727 12.2797 0.319727 11.8897 0.709727L6.99973 5.58973L2.10973 0.699727C1.71973 0.309727 1.08973 0.309727 0.699727 0.699727C0.309727 1.08973 0.309727 1.71973 0.699727 2.10973L5.58973 6.99973L0.699727 11.8897C0.309727 12.2797 0.309727 12.9097 0.699727 13.2997C1.08973 13.6897 1.71973 13.6897 2.10973 13.2997L6.99973 8.40973L11.8897 13.2997C12.2797 13.6897 12.9097 13.6897 13.2997 13.2997C13.6897 12.9097 13.6897 12.2797 13.2997 11.8897L8.40973 6.99973L13.2997 2.10973C13.6797 1.72973 13.6797 1.08973 13.2997 0.709727Z" fill="#9294A0"/>
              </svg>
            </button>
          </div>
<!--    Content    -->
          <div class="w-full py-4 px-6 text-[#262A41] text-[18px] text-futura">
            <slot name="content"></slot>
          </div>
<!--    Controllers    -->
          <div class="w-full py-4 px-6 flex justify-center sm:justify-end items-center border-t border-[#D1D1D6]">
            <button
              @click="$emit('on-close')"
              class="px-4 py-2 rounded-lg border border-[#D1D1D6] text-[#2979FF]"
            >
              Cancel
            </button>
            <button
              @click="$emit('on-confirm')"
              class="px-4 py-2 ml-4 rounded-lg bg-[#2979FF] text-white"
            >
              {{ buttonText }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>

export default {
  name: 'ModalComponent',
  props: {
    isOpen: {
      type: Boolean,
      default: false
    },
    large: {
      type: Boolean,
      default: false
    },
    buttonText: {
      type: String,
      default: 'Accept'
    }
  }
}
</script>
