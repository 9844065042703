import { createRouter, createWebHistory } from 'vue-router'
import PrivateMailInboxView from '../views/private/PrivateMailInboxView.vue'
import MailView from '@/views/private/PrivateMailView.vue'
import EmailLoginView from '@/views/auth/EmailLoginView.vue'
import ResetPasswordView from '@/views/auth/ResetPasswordView.vue'
import SsoLoginVIew from '@/views/auth/SsoLoginVIew.vue'
import PublicMailboxView from '@/views/public/PublicMailboxView.vue'
import PublicMailView from '@/views/public/PublicMailView.vue'

const routes = [
  {
    path: '/login',
    name: 'EmailLogin',
    component: EmailLoginView,
    meta: { title: 'Mailinator | Login' }
  },
  {
    path: '/sso-login',
    name: 'SsoLogin',
    component: SsoLoginVIew,
    meta: { title: 'Mailinator | SSO Login' }
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: ResetPasswordView,
    meta: { title: 'Mailinator | Forgot Password' }
  },
  {
    path: '/public/inbox',
    name: 'PublicMailInbox',
    component: PublicMailboxView,
    meta: { title: 'Mailinator | Public Inbox' }
  },
  {
    path: '/public/message',
    name: 'PublicMail',
    component: PublicMailView,
    meta: { title: 'Mailinator | Public Mail' }
  },
  {
    path: '/private/inbox',
    name: 'PrivateMailInbox',
    component: PrivateMailInboxView,
    meta: {
      title: 'Mailinator | Private Inbox',
      requiresAuth: true
    }
  },
  {
    path: '/private/message',
    name: 'PrivateMail',
    component: MailView,
    meta: {
      title: 'Mailinator | Mail',
      requiresAuth: true
    }
  },
  {
    path: '/:catchAll(.*)',
    redirect: '/public/inbox'
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  document.title = to.meta.title ? to.meta.title : 'Mailinator'
  next()
  // const routerAuthCheck = to.matched.some(route => route.meta.requiresAuth)
  // const accessToken = JSON.parse(localStorage.getItem('mailinator-userTokens'))?.refreshToken
  // if (routerAuthCheck) {
  //   if (accessToken) next()
  //   else next({ name: 'EmailLogin' })
  // } else if (!routerAuthCheck) {
  //   if (!accessToken) next()
  //   else next({ name: 'PublicMailInbox' })
  // }
})

export default router
