<template>
  <PublicMailLayout>
    <LoaderComponent v-if="isLoading"/>
    <PublicMailsListComponent
      v-else
      :mails-list="data"
      @update-data="updateData"
    />
  </PublicMailLayout>
</template>

<script>

import LoaderComponent from '@/components/include/LoaderComponent.vue'
import PublicMailLayout from '@/layouts/PublicMailLayout.vue'
import PublicMailsListComponent from '@/components/public/mailsList/PublicMailsListComponent.vue'
import { connectWebSocket, createRoom, disconnectWebSocket } from '@/http/services/public/publicWebsocket.service'
export default {
  name: 'PublicMailInboxView',
  components: {
    PublicMailsListComponent,
    LoaderComponent,
    PublicMailLayout
  },
  data () {
    return {
      isLoading: false,
      data: null,
      roomName: ''
    }
  },
  watch: {
    data () {
      if (this.data) this.isLoading = false
    }
  },
  async created () {
    this.isLoading = true
    this.roomName = this.$route.query?.inbox

    await this.connectAndCreateRoom()
  },
  async beforeUnmount () {
    disconnectWebSocket()
  },
  methods: {
    async connectAndCreateRoom () {
      await connectWebSocket(this.handleMessage)
      await createRoom(this.roomName)
    },
    handleMessage (data) {
      if (data.channel === 'initial_msgs') {
        this.data = data.msgs.length ? data.msgs.reverse() : []
      } else if (data.channel === 'msg') this.data.unshift(data)
      else if (data.channel === 'error') this.data = []
    },
    updateData (val) {
      this.data = val
    }
  }
}
</script>
