<template>
  <div
    v-if="isNavbarOpen"
    class="fixed top-0 left-0 w-full z-10 h-screen sm:w-[240px] overflow-auto flex flex-col justify-start items-center lg:hidden bg-gradient-to-b from-[#2A2F48] to-[#141622] pb-4 xs:border-r xs:border-[#151724]"
  >
<!--  Toggle navbar button  -->
    <button
      @click="$emit('toggle-navbar')"
      class="mt-2 sm:hidden"
    >
      <svg class="w-[25px] h-[25px] mt-3 md:mt-0 lg:hidden" viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 13.813C0 13.1534 0.510195 12.626 1.13082 12.626H10C10.6206 12.626 11.1308 13.1534 11.1308 13.813C11.1308 14.4726 10.6206 15 10 15H1.13082C0.510195 15 0 14.4726 0 13.813Z" fill="white"/>
        <path d="M0 7.37116C0 6.71159 0.510195 6.18415 1.13082 6.18415H18.8692C19.4898 6.18415 20 6.71159 20 7.37116C20 8.03074 19.4898 8.55818 18.8692 8.55818H1.13082C0.510195 8.55818 0 8.03074 0 7.37116Z" fill="white"/>
        <path d="M0 1.18701C0 0.527438 0.510195 0 1.13082 0H18.8692C19.4898 0 20 0.527438 20 1.18701C20 1.84659 19.4898 2.37403 18.8692 2.37403H1.13082C0.510195 2.37403 0 1.84659 0 1.18701Z" fill="white"/>
      </svg>
    </button>
<!--  Logo  -->
    <img
      :src="require('@/assets/Logo.svg')"
      alt="logo" class="my-6"
    >
    <nav class="w-full flex flex-col justify-start items-center">
<!--    Private Team Inbox    -->
      <router-link
        v-if="isPrivateMenu"
        :to="{ name: 'PrivateMailInbox' }"
        class="px-4 py-3 w-full text-center text-[#8E95BD] border-b border-[#151724] hover:text-white hover:bg-[#3A4062]"
        :class="{'text-white bg-[#3A4062]': $route.name === 'PrivateMailInbox'}"
      >
        <span>Private Team Inbox</span>
      </router-link>
<!--    Team Settings    -->
      <router-link
        v-if="isPrivateMenu"
        :to="{ name: 'TeamSettings' }"
        class="px-4 py-3 w-full text-center text-[#8E95BD] border-b border-[#151724] hover:text-white hover:bg-[#3A4062]"
        :class="{'text-white bg-[#3A4062]': $route.name === 'TeamSettings'}"
      >
        <span>Team Settings</span>
      </router-link>
<!--    Team Management    -->
      <router-link
        v-if="isPrivateMenu"
        :to="{ name: 'TeamManagement' }"
        class="px-4 py-3 w-full text-center text-[#8E95BD] border-b border-[#151724] hover:text-white hover:bg-[#3A4062]"
        :class="{'text-white bg-[#3A4062]': $route.name === 'TeamManagement'}"
      >
        <span>Team Management</span>
      </router-link>
<!--    Message Rules    -->
      <router-link
        v-if="isPrivateMenu"
        :to="{ name: 'MessageRules' }"
        class="px-4 py-3 w-full text-center text-[#8E95BD] border-b border-[#151724] hover:text-white hover:bg-[#3A4062]"
        :class="{'text-white bg-[#3A4062]': $route.name === 'MessageRules'}"
      >
        <span>Message Rules</span>
      </router-link>
<!--    Authenticator    -->
      <router-link
        v-if="isPrivateMenu"
        :to="{ name: 'Authenticator' }"
        class="px-4 py-3 w-full text-center text-[#8E95BD] border-b border-[#151724] hover:text-white hover:bg-[#3A4062]"
        :class="{'text-white bg-[#3A4062]': $route.name === 'Authenticator'}"
      >
        <span>Authenticator</span>
      </router-link>
<!--    Public inboxes    -->
      <router-link
        :to="{ name: 'PublicMailInbox' }"
        class="px-4 py-3 w-full text-center text-[#8E95BD] border-b border-[#151724] hover:text-white hover:bg-[#3A4062]"
        :class="{'text-white bg-[#3A4062]': $route.name === 'PublicMailInbox'}"
      >
        <span>Public inboxes</span>
      </router-link>
<!--    Quick Starts    -->
      <a
        href="https://www.mailinator.com/v4/private/quick_start.jsp"
        class="px-4 py-3 w-full text-center text-[#8E95BD] border-b border-[#151724] hover:text-white hover:bg-[#3A4062]"
      >
        <span>Quick Starts</span>
      </a>
<!--    Home    -->
      <a
        href="https://www.mailinator.com/index.jsp"
        class="px-4 py-3 w-full text-center text-[#8E95BD] border-b border-[#151724] hover:text-white hover:bg-[#3A4062]"
      >
        <span>Home</span>
      </a>
<!--    Documentation    -->
      <a
        href="https://www.mailinator.com/docs/index.html#mailinator"
        class="px-4 py-3 w-full text-center text-[#8E95BD] border-b border-[#151724] hover:text-white hover:bg-[#3A4062]"
      >
        <span>Documentation</span>
      </a>
<!--    FAQ    -->
      <a
        href="https://www.mailinator.com/mailinator-faqs/"
        class="px-4 py-3 w-full text-center text-[#8E95BD] border-b border-[#151724] hover:text-white hover:bg-[#3A4062]"
      >
        <span>FAQ</span>
      </a>
<!--    System Status    -->
      <a
        v-if="isPrivateMenu"
        href="https://monitor.mailinator.com/status/mailinator"
        class="px-4 py-3 w-full text-center text-[#8E95BD] border-b border-[#151724] hover:text-white hover:bg-[#3A4062]"
      >
        <span>System Status</span>
      </a>
<!--    Terms & Conditions    -->
      <a
        href="https://www.mailinator.com/terms-of-use/"
        class="px-4 py-3 w-full text-center text-[#8E95BD] border-b border-[#151724] hover:text-white hover:bg-[#3A4062]"
      >
        <span>Terms & Conditions</span>
      </a>
<!--    Privacy Policy    -->
      <a
        href="https://www.mailinator.com/privacy-policy/"
        class="px-4 py-3 w-full text-center text-[#8E95BD] border-b border-[#151724] hover:text-white hover:bg-[#3A4062]"
      >
        <span>Privacy Policy</span>
      </a>
<!--    Accessibility    -->
      <a
        href="https://www.mailinator.com/accessibility/"
        class="px-4 py-3 w-full text-center text-[#8E95BD] border-b border-[#151724] hover:text-white hover:bg-[#3A4062]"
      >
        <span>Accessibility</span>
      </a>
<!--    Security    -->
      <a
        href="https://www.mailinator.com/security-details/"
        class="px-4 py-3 w-full text-center text-[#8E95BD] border-b border-[#151724] hover:text-white hover:bg-[#3A4062]"
      >
        <span>Security</span>
      </a>
    </nav>
<!--  logout  -->
    <button
      v-if="isPrivateMenu"
      @click="$emit('open-logout-confirm')"
      class="px-4 py-3 w-full text-center text-[#8E95BD] border-b border-[#151724] hover:text-white hover:bg-[#3A4062]"
    >
      Logout
    </button>
    <!--    login    -->
    <router-link
      v-if="!isPrivateMenu"
      :to="{ name: 'EmailLogin' }"
      class="px-4 py-3 w-full text-center text-[#8E95BD] border-b border-[#151724] hover:text-white hover:bg-[#3A4062]"
    >
      <span>Login</span>
    </router-link>
  </div>
</template>

<script>

export default {
  name: 'NavbarMobileScreenComponent',
  props: {
    isNavbarOpen: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isPrivateMenu () {
      return this.$route.params.meta?.requiresAuth
    }
  }
}
</script>
