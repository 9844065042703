import BaseAxiosInstance from '@/axiosConfig'
import { useToast } from 'vue-toastification'

const toast = useToast()
const domain = process.env.VUE_APP_DOMAIN

export let inbox = '*'

const generateUrl = (url) => {
  return domain + url
}

const API = {
  FETCH_MESSAGE: (id) => generateUrl(`/fetch_public?msgid=${id}`),
  DELETE_MESSAGE: (id) => generateUrl(`/delete_public?msgid=${id}`)
}

const PublicMessagesService = {
  async fetchPublicMessage (id) {
    return BaseAxiosInstance.get(API.FETCH_MESSAGE(id))
  },

  async deletePublicMessages (IDs) {
    const requests = IDs.map(id => {
      return BaseAxiosInstance.delete(API.DELETE_MESSAGE(inbox, id))
    })

    Promise.all(requests)
      .then(responses => {
        if (responses[0]?.status === 'ok') toast.success(`${responses.length === 1 ? 'Email' : 'Emails'} was deleted`)
      })
  },

  setInbox (newInbox) {
    inbox = newInbox
  }
}

export default PublicMessagesService
