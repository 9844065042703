<template>
  <button
    @click="isOpen = true"
    class="flex justify-center items-center p-4"
  >
    <svg width="12" height="16" viewBox="0 0 12 16" fill="#2979FF" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.00008 13.8333C1.00008 14.75 1.75008 15.5 2.66675 15.5H9.33341C10.2501 15.5 11.0001 14.75 11.0001 13.8333V3.83333H1.00008V13.8333ZM11.8334 1.33333H8.91675L8.08341 0.5H3.91675L3.08341 1.33333H0.166748V3H11.8334V1.33333Z" fill="#2979FF"/>
    </svg>
    <span class="ml-3">Delete</span>
  </button>
  <ModalComponent
    :is-open="isOpen"
    @on-close="() => isOpen = false"
    @on-confirm="deleteCheckedMessages"
  >
    <template v-slot:head>
      Deleting selected {{isOnceMessage ? 'email' : 'emails'}}
    </template>
    <template v-slot:content>
      Are you sure you want to delete the selected {{isOnceMessage ? 'email' : 'emails'}}?
    </template>
  </ModalComponent>
</template>
<script>

import ModalComponent from '@/components/include/ModalComponent.vue'
export default {
  name: 'DeleteEmailComponent',
  components: { ModalComponent },
  emits: ['delete-checked-email'],
  props: {
    isOnceMessage: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isOpen: false
    }
  },
  methods: {
    deleteCheckedMessages () {
      this.isOpen = false
      this.$emit('delete-checked-email')
    }
  }
}
</script>
