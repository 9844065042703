<template>
  <div class="mt-4 px-2 md:px-0">
    <div class="md:hidden">
      <label for="tabs" class="text-[18px] text-[#262A41] font-semibold">Select tab</label>
      <select
        @change="setCurrentTabFromSelect($event)"
        id="tabs" name="tabs"
        class="w-full mx-auto rounded-md border-2 border-[#141622] py-2 pl-3 pr-10 selected:text-[#2979FF] focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm font-futura"
      >
        <option
          v-for="tab in tabs.filter(t => t.isActive)" :key="tab.name"
          :selected="tab.current"
        >
          {{ tab.name }}
        </option>
      </select>
    </div>
    <div class="hidden md:block">
      <div class="border-b-2 border-[#D1D1D6] font-futura">
        <nav class="-mb-px lg:px-4 flex space-x-7">
          <a
            v-for="(tab, index) in tabs.filter(t => t.isActive)" :key="tab.name"
            @click="setCurrentTab(index)"
            :class="[
              tab.current
              ? 'border-[#0a53cf] text-[#0a53cf] px-0 xl:px-4'
              : 'border-transparent hover:border-[#0a53cf] hover:text-[#0a53cf]',
              'whitespace-nowrap border-b-2 px-2 xl:px-4 py-4 text-[sm] xl:text-[18px] text-[#262A41] cursor-pointer font-medium'
              ]"
            :aria-current="tab.current ? 'page' : undefined"
          >
            {{ tab.name }}
          </a>
        </nav>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: 'TabsComponent',
  data () {
    return {
      tabs: [
        { name: 'TEXT', href: '#', current: true, isActive: true },
        { name: 'HTML', href: '#', current: false, isActive: true },
        { name: 'JSON', href: '#', current: false, isActive: true },
        { name: 'RAW', href: '#', current: false, isActive: true },
        { name: 'LINKS', href: '#', current: false, isActive: true },
        { name: 'SMTP_LOGS', href: '#', current: false, isActive: true },
        { name: 'ATTACHMENTS', href: '#', current: false, isActive: !this.$route.name === 'PublicMail' }
      ]
    }
  },
  methods: {
    setCurrentTabFromSelect (event) {
      const val = event.target.value
      const index = this.tabs.findIndex(t => t.name === val)
      this.setCurrentTab(index)
    },
    setCurrentTab (index) {
      this.tabs.forEach(t => {
        t.current = false
      })
      this.tabs[index].current = true
      this.$emit('set-current-tab', this.tabs[index].name)
    }
  }
}
</script>
