<template>
  <button
    @click="isOpen = true"
    class="flex justify-center items-center p-4"
  >
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.00004 3.66668V0.333344L13.6667 7.00001L7.00004 13.6667V10.3333H0.333374V3.66668H7.00004Z" fill="#2979FF"/>
    </svg>
    <span class="ml-3">Forward</span>
  </button>
  <ModalComponent
    :is-open="isOpen"
    @on-close="() => isOpen = false"
    @on-confirm="onConfirmDialog"
  >
    <template v-slot:head>
      Forward {{isOnceMessage ? 'email' : 'selected emails'}}
    </template>
    <template v-slot:content>
      Are you sure you want to forward {{isOnceMessage ? 'this email' : 'the selected emails'}}?
    </template>
  </ModalComponent>
</template>

<script>

import ModalComponent from '@/components/include/ModalComponent.vue'

export default {
  name: 'ForwardEmailComponent',
  components: { ModalComponent },
  emits: ['forward-emails'],
  props: {
    isOnceMessage: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isOpen: false
    }
  },
  methods: {
    onConfirmDialog () {
      this.isOpen = false
      this.$emit('forward-emails')
    }
  }
}
</script>
