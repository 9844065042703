export const convertSecondsToTime = (seconds) => {
  const secondsInYear = 31536000
  const secondsInMonth = 2628000
  const secondsInDay = 86400
  const secondsInHour = 3600
  const secondsInMinute = 60
  const years = Math.floor(seconds / secondsInYear)
  let remainingSeconds = seconds % secondsInYear

  const months = Math.floor(remainingSeconds / secondsInMonth)
  remainingSeconds %= secondsInMonth
  const days = Math.floor(remainingSeconds / secondsInDay)
  remainingSeconds %= secondsInDay

  const hours = Math.floor(remainingSeconds / secondsInHour)
  remainingSeconds %= secondsInHour

  const minutes = Math.floor(remainingSeconds / secondsInMinute)
  // const finalSeconds = remainingSeconds % secondsInMinute

  if (years) {
    return years + ' years'
  } else if (months) {
    return months + ' months'
  } else if (days) {
    return days + ' days'
  } else if (hours) {
    return hours + ' hours'
  } else if (minutes) {
    return minutes + ' minutes'
  } else {
    return 'just now'
  }
}
