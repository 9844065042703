<template>
  <div class="flex w-[240px] flex-col justify-start items-center font-futura text-[18px]">
    <p class="text-white text-[21px] font-futura">Recent Inboxes</p>
    <button
      v-for="(inbox, index) of recentInboxes" :key="index"
      @click="updateInbox(inbox)"
      class="mx-1 mb-1 px-4 py-3 w-[210px] text-center whitespace-nowrap rounded-lg text-[#8E95BD] hover:text-white hover:bg-[#3A4062]"
    >
      <p class="truncate">{{ inbox }}</p>
    </button>
  </div>
</template>

<script>
import { createRoom } from '@/http/services/public/publicWebsocket.service'

export default {
  name: 'RecentInboxes',
  computed: {
    recentInboxes () {
      return localStorage.getItem('recent-inboxes')
        ? JSON.parse(localStorage.getItem('recent-inboxes'))
        : []
    }
  },
  methods: {
    updateInbox (val) {
      if (this.$route.name === 'PublicMail') {
        this.$router.push({
          name: 'PublicMailInbox',
          query: {
            ...this.$route.query,
            inbox: val
          }
        })
      } else {
        createRoom(val)
        this.$router.replace({
          query: {
            ...this.$route.query,
            inbox: val
          }
        })
      }
    }
  }
}
</script>
