<template>
  <div class="mt-2 w-[180px] h-[160px] flex flex-col justify-center items-center rounded-2xl border-[3px] border-[#151724] container">
    <span class="text-white font-futura text-center text-[14px]">Setup your Mailinator Authenticator Now!</span>
    <button class="mt-4 w-[48px] h-[48px] flex justify-center items-center rounded-full bg-[#2979FF]">
      <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1.57707 10.833H14.6087L8.9154 16.5263C8.4604 16.9813 8.4604 17.728 8.9154 18.183C9.3704 18.638 10.1054 18.638 10.5604 18.183L18.2487 10.4947C18.7037 10.0397 18.7037 9.30467 18.2487 8.84967L10.5721 1.14967C10.1171 0.694668 9.38207 0.694668 8.92707 1.14967C8.47207 1.60467 8.47207 2.33967 8.92707 2.79467L14.6087 8.49967H1.57707C0.9354 8.49967 0.4104 9.02467 0.4104 9.66634C0.4104 10.308 0.9354 10.833 1.57707 10.833Z" fill="white"/>
      </svg>
    </button>
  </div>
</template>

<script>

export default {
  name: 'MailinatorSetupLinkComponent'
}
</script>
<style lang="scss">
.container {
  background: linear-gradient(135deg, #38448D 0%, #151724 36%, #151724 64%, #38448D 100%);
}
</style>
