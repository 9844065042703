const eventHash = {
  s: 'SOCKET_OPEN',
  a: 'MSG_SAVED',
  c: 'SOCKET_CLOSED',
  i: 'INCOMING',
  o: 'OUTGOING',
  t: 'TLS_ACTIVE',
  d: 'DATA',
  e: 'ERROR'
}
export const createJsonVersion = (s) => {
  const ja = []
  s.split('|').forEach((t) => {
    const jo = {}
    let p = 0
    while (t.charAt(p) >= '0' && t.charAt(p) <= '9') {
      p++
    }
    jo.time = t.substring(0, p)
    jo.event = eventHash[t.charAt(p)]
    if (p + 1 < t.length) {
      jo.log = t.substring(p + 1)
    }
    ja.push(jo)
  })
  return ja
}
