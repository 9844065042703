<template>
  <nav class="flex w-[240px] flex-col justify-start items-center font-futura text-[18px]">
<!--    Private Team Inbox    -->
    <router-link
      :to="{ name: 'PrivateMailInbox' }"
      class="mx-1 mb-1 px-4 py-3 w-[210px] flex justify-start items-center rounded-lg text-[#8E95BD] hover:text-white hover:bg-[#3A4062] link"
      :class="{'active-link text-white bg-[#3A4062]': $route.name === 'PrivateMailInbox'}"
    >
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" class="mr-3"><path d="M13.833.5H2.158c-.925 0-1.65.742-1.65 1.667L.5 13.833A1.66 1.66 0 0 0 2.158 15.5h11.675c.917 0 1.667-.75 1.667-1.667V2.167C15.5 1.242 14.75.5 13.833.5Zm0 10H10.5c0 1.383-1.125 2.5-2.5 2.5a2.502 2.502 0 0 1-2.5-2.5H2.158V2.167h11.675V10.5Z"/></svg>
      <span>Private Team Inbox</span>
    </router-link>
<!--    Team Settings    -->
    <a
      href="https://www.mailinator.com/v4/private/team_settings.jsp"
      class="mx-1 mb-1 px-4 py-3 w-[210px] flex justify-start items-center rounded-lg text-[#8E95BD] hover:text-white hover:bg-[#3A4062] link"
      :class="{'active-link text-white bg-[#3A4062]': $route.name === 'TeamSettings'}"
    >
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" class="mr-3">
        <path d="M13.9499 8.78367C13.9833 8.53367 13.9999 8.27534 13.9999 8.00034C13.9999 7.73367 13.9833 7.467 13.9416 7.217L15.6333 5.90034C15.7833 5.78367 15.8249 5.55867 15.7333 5.392L14.1333 2.62534C14.0333 2.442 13.8249 2.38367 13.6416 2.442L11.6499 3.242C11.2333 2.92534 10.7916 2.65867 10.2999 2.45867L9.99993 0.342002C9.96659 0.142002 9.79993 0.000335693 9.59993 0.000335693H6.39993C6.19993 0.000335693 6.04159 0.142002 6.00826 0.342002L5.70826 2.45867C5.2166 2.65867 4.7666 2.93367 4.35826 3.242L2.3666 2.442C2.18326 2.37534 1.97493 2.442 1.87493 2.62534L0.283262 5.392C0.183262 5.567 0.216595 5.78367 0.383262 5.90034L2.07493 7.217C2.03326 7.467 1.99993 7.742 1.99993 8.00034C1.99993 8.25867 2.0166 8.53367 2.05826 8.78367L0.366595 10.1003C0.216595 10.217 0.174929 10.442 0.266595 10.6087L1.8666 13.3753C1.9666 13.5587 2.17493 13.617 2.35826 13.5587L4.34993 12.7587C4.76659 13.0753 5.20826 13.342 5.69993 13.542L5.99993 15.6587C6.0416 15.8587 6.19993 16.0003 6.39993 16.0003H9.59993C9.79993 16.0003 9.9666 15.8587 9.9916 15.6587L10.2916 13.542C10.7833 13.342 11.2333 13.0753 11.6416 12.7587L13.6333 13.5587C13.8166 13.6253 14.0249 13.5587 14.1249 13.3753L15.7249 10.6087C15.8249 10.4253 15.7833 10.217 15.6249 10.1003L13.9499 8.78367ZM7.99993 11.0003C6.34993 11.0003 4.99993 9.65034 4.99993 8.00034C4.99993 6.35034 6.34993 5.00034 7.99993 5.00034C9.64993 5.00034 10.9999 6.35034 10.9999 8.00034C10.9999 9.65034 9.64993 11.0003 7.99993 11.0003Z"/>
      </svg>
      <span>Team Settings</span>
    </a>
<!--    Team Management    -->
    <a
      href="https://www.mailinator.com/v4/private/team_management.jsp"
      class="mx-1 mb-1 px-4 py-3 w-[210px] flex justify-start items-center rounded-lg text-[#8E95BD] hover:text-white hover:bg-[#3A4062] link"
      :class="{'active-link text-white bg-[#3A4062]': $route.name === 'TeamManagement'}"
    >
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="16" class="mr-3">
        <path d="M3.33333 13.75C4.25381 13.75 5 13.0038 5 12.0833C5 11.1629 4.25381 10.4167 3.33333 10.4167C2.41286 10.4167 1.66667 11.1629 1.66667 12.0833C1.66667 13.0038 2.41286 13.75 3.33333 13.75Z"/>
        <path d="M1.01667 15.0667C0.4 15.3333 0 15.9333 0 16.6083V17.9167H3.75V16.575C3.75 15.8833 3.94167 15.2333 4.275 14.6667C3.96667 14.6167 3.65833 14.5833 3.33333 14.5833C2.50833 14.5833 1.725 14.7583 1.01667 15.0667Z"/>
        <path d="M16.6667 13.75C17.5871 13.75 18.3333 13.0038 18.3333 12.0833C18.3333 11.1629 17.5871 10.4167 16.6667 10.4167C15.7462 10.4167 15 11.1629 15 12.0833C15 13.0038 15.7462 13.75 16.6667 13.75Z"/>
        <path d="M18.9833 15.0667C18.275 14.7583 17.4917 14.5833 16.6667 14.5833C16.3417 14.5833 16.0333 14.6167 15.725 14.6667C16.0583 15.2333 16.25 15.8833 16.25 16.575V17.9167H20V16.6083C20 15.9333 19.6 15.3333 18.9833 15.0667Z"/>
        <path d="M13.5333 14.2917C12.5583 13.8583 11.3583 13.5417 10 13.5417C8.64167 13.5417 7.44167 13.8667 6.46667 14.2917C5.56667 14.6917 5 15.5917 5 16.575V17.9167H15V16.575C15 15.5917 14.4333 14.6917 13.5333 14.2917Z"/>
        <path d="M7.5 10.4167C7.5 11.8 8.61667 12.9167 10 12.9167C11.3833 12.9167 12.5 11.8 12.5 10.4167C12.5 9.03334 11.3833 7.91668 10 7.91668C8.61667 7.91668 7.5 9.03334 7.5 10.4167Z"/>
        <path d="M2.06667 9.46668C1.80833 8.83334 1.66667 8.21668 1.66667 7.58334C1.66667 5.43334 3.35 3.75001 5.5 3.75001C7.73333 3.75001 8.68333 5.20001 10 6.74168C11.3083 5.21668 12.25 3.75001 14.5 3.75001C16.65 3.75001 18.3333 5.43334 18.3333 7.58334C18.3333 8.21668 18.1917 8.83334 17.9333 9.46668C18.475 9.72501 18.9167 10.15 19.2083 10.6667C19.7083 9.66668 20 8.65001 20 7.58334C20 4.50001 17.5833 2.08334 14.5 2.08334C12.7583 2.08334 11.0917 2.89168 10 4.17501C8.90833 2.89168 7.24167 2.08334 5.5 2.08334C2.41667 2.08334 0 4.50001 0 7.58334C0 8.65001 0.291667 9.66668 0.8 10.6667C1.09167 10.15 1.53333 9.72501 2.06667 9.46668Z"/>
      </svg>
      <span>Team Management</span>
    </a>
<!--    Message Rules    -->
    <a
      href="https://www.mailinator.com/v4/private/message_rules.jsp"
      class="mx-1 mb-1 px-4 py-3 w-[210px] flex justify-start items-center rounded-lg text-[#8E95BD] hover:text-white hover:bg-[#3A4062] link"
      :class="{'active-link text-white bg-[#3A4062]': $route.name === 'MessageRules'}"
    >
      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" class="mr-3">
        <path d="M9.00008 0.666672C4.40008 0.666672 0.666748 4.40001 0.666748 9.00001C0.666748 13.6 4.40008 17.3333 9.00008 17.3333C13.6001 17.3333 17.3334 13.6 17.3334 9.00001C17.3334 4.40001 13.6001 0.666672 9.00008 0.666672ZM9.00008 9.83334C8.54175 9.83334 8.16675 9.45834 8.16675 9.00001V5.66667C8.16675 5.20834 8.54175 4.83334 9.00008 4.83334C9.45842 4.83334 9.83342 5.20834 9.83342 5.66667V9.00001C9.83342 9.45834 9.45842 9.83334 9.00008 9.83334ZM9.83342 13.1667H8.16675V11.5H9.83342V13.1667Z"/>
      </svg>
      <span>Message Rules</span>
    </a>
<!--    Authenticator    -->
    <a
      href="https://www.mailinator.com/v4/private/mailinator_authenticator.jsp"
      class="mx-1 mb-1 px-4 py-3 w-[210px] flex justify-start items-center rounded-lg text-[#8E95BD] hover:text-white hover:bg-[#3A4062] link"
      :class="{'active-link text-white bg-[#3A4062]': $route.name === 'Authenticator'}"
    >
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="16" class="mr-3">
        <path d="M17.4999 0.508331H2.49992C1.58325 0.508331 0.833252 1.25833 0.833252 2.175V4.66666C0.833252 5.125 1.20825 5.5 1.66659 5.5C2.12492 5.5 2.49992 5.125 2.49992 4.66666V2.99166C2.49992 2.53333 2.87492 2.15833 3.33325 2.15833H16.6666C17.1249 2.15833 17.4999 2.53333 17.4999 2.99166V13.0167C17.4999 13.475 17.1249 13.85 16.6666 13.85H3.33325C2.87492 13.85 2.49992 13.475 2.49992 13.0167V11.3333C2.49992 10.875 2.12492 10.5 1.66659 10.5C1.20825 10.5 0.833252 10.875 0.833252 11.3333V13.8417C0.833252 14.75 1.57492 15.4917 2.48325 15.4917H17.4999C18.4166 15.4917 19.1666 14.7417 19.1666 13.825V2.175C19.1666 1.25833 18.4166 0.508331 17.4999 0.508331ZM9.87492 10.625L12.1999 8.3C12.3666 8.13333 12.3666 7.875 12.1999 7.70833L9.87492 5.38333C9.61658 5.11666 9.16658 5.3 9.16658 5.675V7.16666H1.66659C1.20825 7.16666 0.833252 7.54166 0.833252 8C0.833252 8.45833 1.20825 8.83333 1.66659 8.83333H9.16658V10.325C9.16658 10.7 9.61658 10.8833 9.87492 10.625Z"/>
      </svg>
      <span>Authenticator</span>
    </a>
<!--    Quick Starts    -->
    <a
      href="https://www.mailinator.com/v4/private/quick_start.jsp"
      class="mx-1 mb-1 px-4 py-3 w-[210px] flex justify-start items-center rounded-lg text-[#8E95BD] hover:text-white hover:bg-[#3A4062] link"
    >
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" class="mr-3">
        <path d="M15.0083 5.00833L10.9833 0.983333C10.675 0.675 10.25 0.5 9.80833 0.5H2.16667C1.25 0.5 0.5 1.25 0.5 2.16667V13.8333C0.5 14.75 1.25 15.5 2.16667 15.5H13.8333C14.75 15.5 15.5 14.75 15.5 13.8333V6.19167C15.5 5.75 15.325 5.325 15.0083 5.00833ZM3.83333 3.83333H9.66667V5.5H3.83333V3.83333ZM12.1667 12.1667H3.83333V10.5H12.1667V12.1667ZM12.1667 8.83333H3.83333V7.16667H12.1667V8.83333Z"/>
      </svg>
      <span>Quick Starts</span>
    </a>
<!--    Public inboxes    -->
    <router-link
      :to="{ name: 'PublicMailInbox' }"
      class="mx-1 mb-1 px-4 py-3 w-[210px] flex justify-start items-center rounded-lg text-[#8E95BD] hover:text-white hover:bg-[#3A4062] link"
    >
      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="14" class="mr-3">
        <path d="M15.6667 0.333336H2.33341C1.41675 0.333336 0.675081 1.08334 0.675081 2L0.666748 12C0.666748 12.9167 1.41675 13.6667 2.33341 13.6667H15.6667C16.5834 13.6667 17.3334 12.9167 17.3334 12V2C17.3334 1.08334 16.5834 0.333336 15.6667 0.333336ZM15.6667 3.66667L9.00008 7.83334L2.33341 3.66667V2L9.00008 6.16667L15.6667 2V3.66667Z"/>
      </svg>
      <span>Public inboxes</span>
    </router-link>
  </nav>
</template>

<script>
export default {
  name: 'PrivateNavigationListComponent'
}
</script>
<style lang="scss" scoped>
.link svg {
  fill: #8E95BD
}
.link:hover svg {
  fill: white;
}
.active-link svg{
  fill: white;
}
</style>
