<template>
  <div
    v-if="data.length"
    class="h-auto max-h-[calc(100vh_-_345px)] 2xl:max-h-[calc(100vh_-_375px)] p-8 xl:mx-2 flex flex-wrap justify-center lg:justify-start items-center overflow-auto whitespace-pre-line"
  >
    <div
      v-for="(i, index) of data" :key="index"
      class="w-[360px] p-4 m-4 flex flex-wrap justify-between items-center rounded-lg bg-[#F2F2F3]"
    >
      <div class="flex items-center">
        <svg
          v-if="getAttachType(i.headers['content-type']) === 'docx'"
          width="40" height="48"
          viewBox="0 0 40 48" fill="none" xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M39.4621 13.2562V42.2156C39.4621 45.4125 36.8746 48 33.6778 48H10.7278C7.53089 48 4.94338 45.4125 4.94338 42.2156V5.78438C4.93401 2.5875 7.53089 0 10.7278 0H26.2059L39.4621 13.2562Z" fill="white"/>
          <path d="M39.4623 13.2562H28.5967C27.2748 13.2562 26.2061 12.1875 26.2061 10.8656V0L39.4623 13.2562Z" fill="#21B045" fill-opacity="0.75"/>
          <path d="M4.08086 19.7531H32.7965C34.7559 19.7531 36.3402 21.3375 36.3402 23.2969V31.9219C36.3402 33.8813 34.7559 35.4656 32.7965 35.4656H4.08086C2.12148 35.4656 0.537109 33.8813 0.537109 31.9219V23.2969C0.537109 21.3375 2.12148 19.7531 4.08086 19.7531Z" fill="#21B045"/>
          <path d="M23.1885 31.8278L25.4296 27.7876L23.5398 24.1721H25.0213L26.1323 26.548L27.3004 24.1721H28.7818L26.835 27.7876L29.0002 31.8278H27.5188L26.1323 29.0272L24.6699 31.8278H23.1885Z" fill="white"/>
          <path d="M22.9831 29.8077V31.5524C22.5146 31.8508 21.9923 32 21.4162 32C20.4919 32 19.7195 31.6442 19.0991 30.9326C18.4217 30.1444 18.083 29.1688 18.083 28.0057C18.083 26.7661 18.4217 25.7676 19.0991 25.01C19.6942 24.3367 20.4697 24 21.4257 24C21.9828 24 22.5019 24.1569 22.9831 24.4706V26.2152C22.5716 25.6413 22.0619 25.3544 21.4542 25.3544C20.8338 25.3544 20.3273 25.6184 19.9348 26.1463C19.5486 26.6667 19.3555 27.2941 19.3555 28.0287C19.3555 28.8321 19.5708 29.4711 20.0013 29.9455C20.4318 30.4122 20.9319 30.6456 21.5017 30.6456C22.0968 30.6456 22.5906 30.3663 22.9831 29.8077Z" fill="white"/>
          <path d="M11.7136 25.1248C12.3467 24.3749 13.1602 24 14.1541 24C15.1481 24 15.9584 24.3749 16.5852 25.1248C17.2182 25.8747 17.5348 26.835 17.5348 28.0057C17.5348 29.1765 17.2182 30.1368 16.5852 30.8867C15.9584 31.6289 15.1481 32 14.1541 32C13.1602 32 12.3467 31.6289 11.7136 30.8867C11.0868 30.1368 10.7734 29.1765 10.7734 28.0057C10.7734 26.835 11.0868 25.8747 11.7136 25.1248ZM12.6347 29.911C13.0272 30.4008 13.5337 30.6456 14.1541 30.6456C14.7745 30.6456 15.281 30.4008 15.6735 29.911C16.066 29.4137 16.2623 28.7786 16.2623 28.0057C16.2623 27.2329 16.0629 26.5978 15.664 26.1004C15.2715 25.6031 14.7682 25.3544 14.1541 25.3544C13.54 25.3544 13.0336 25.6031 12.6347 26.1004C12.2422 26.5978 12.0459 27.2329 12.0459 28.0057C12.0459 28.7786 12.2422 29.4137 12.6347 29.911Z" fill="white"/>
          <path d="M5 24.1721H6.65235C7.15249 24.1721 7.58932 24.2257 7.96284 24.3328C8.33636 24.4323 8.68455 24.6274 9.00743 24.9182C9.39361 25.2702 9.69116 25.714 9.90008 26.2496C10.1153 26.7852 10.2229 27.3706 10.2229 28.0057C10.2229 29.3448 9.79878 30.374 8.95045 31.0932C8.60858 31.384 8.26039 31.5791 7.90586 31.6786C7.55133 31.7781 7.12716 31.8278 6.63336 31.8278H5V24.1721ZM6.23452 25.4576V30.5423H6.61437C7.26011 30.5423 7.77608 30.374 8.16226 30.0373C8.40916 29.823 8.60225 29.5361 8.74153 29.1764C8.88081 28.8092 8.95045 28.4189 8.95045 28.0057C8.95045 27.6002 8.88081 27.2176 8.74153 26.8579C8.60858 26.4906 8.41866 26.1922 8.17175 25.9627C7.79823 25.626 7.2791 25.4576 6.61437 25.4576H6.23452Z" fill="white"/>
        </svg>
        <svg
          v-else-if="getAttachType(i.headers['content-type']) === 'jpg'"
          width="42" height="40"
          viewBox="0 0 42 40" fill="none" xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M36 0H6C2.68629 0 0 2.38781 0 5.33333V34.6667C0 37.6122 2.68629 40 6 40H36C39.3137 40 42 37.6122 42 34.6667V5.33333C42 2.38781 39.3137 0 36 0Z" fill="white"/>
          <path d="M32.2501 17.944C31.966 17.7068 31.5891 17.5772 31.1994 17.5827C30.8096 17.5881 30.4376 17.7282 30.1621 17.9733L23.6941 23.724C23.4129 23.974 23.2549 24.3131 23.2549 24.6667C23.2549 25.0202 23.4129 25.3593 23.6941 25.6093L39.0286 39.244C39.9301 38.7778 40.6786 38.11 41.1999 37.307C41.7212 36.504 41.9971 35.5938 42.0001 34.6667V26.0893L32.2501 17.944Z" fill="#4071F7"/>
          <path d="M25.5 17.3333C27.9853 17.3333 30 15.5425 30 13.3333C30 11.1242 27.9853 9.33333 25.5 9.33333C23.0147 9.33333 21 11.1242 21 13.3333C21 15.5425 23.0147 17.3333 25.5 17.3333Z" fill="#5392F9"/>
          <path d="M17.5605 16.3907C17.2739 16.1498 16.8944 16.0155 16.5 16.0155C16.1056 16.0155 15.7261 16.1498 15.4395 16.3907L0 30.1147V34.6667C0 36.0811 0.632141 37.4377 1.75736 38.4379C2.88258 39.4381 4.4087 40 6 40H36C37.043 39.9973 38.067 39.7521 38.9704 39.2888C39.8738 38.8254 40.625 38.16 41.1495 37.3587L17.5605 16.3907Z" fill="#5392F9"/>
        </svg>
        <svg
          v-else-if="getAttachType(i.headers['content-type']) === 'pdf'"
          width="40" height="48"
          viewBox="0 0 40 48" fill="none" xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M39.4621 13.2562V42.2156C39.4621 45.4125 36.8746 48 33.6778 48H10.7278C7.53089 48 4.94338 45.4125 4.94338 42.2156V5.78438C4.93401 2.5875 7.53089 0 10.7278 0H26.2059L39.4621 13.2562Z" fill="white"/>
          <path d="M39.4623 13.2562H28.5967C27.2748 13.2562 26.2061 12.1875 26.2061 10.8656V0L39.4623 13.2562Z" fill="#FF3B30" fill-opacity="0.75"/>
          <path d="M4.08086 19.7531H32.7965C34.7559 19.7531 36.3402 21.3375 36.3402 23.2969V31.9219C36.3402 33.8813 34.7559 35.4656 32.7965 35.4656H4.08086C2.12148 35.4656 0.537109 33.8813 0.537109 31.9219V23.2969C0.537109 21.3375 2.12148 19.7531 4.08086 19.7531Z" fill="#FF3B30"/>
          <path d="M23.9999 24V25.3433H21.1964V27.1304H23.8247V28.4738H21.1964V32H19.5693V24H23.9999Z" fill="white"/>
          <path d="M11.4346 24H13.6123C14.2715 24 14.8472 24.056 15.3395 24.1679C15.8318 24.2719 16.2907 24.4758 16.7163 24.7796C17.2252 25.1474 17.6174 25.6112 17.8927 26.1709C18.1764 26.7306 18.3183 27.3423 18.3183 28.006C18.3183 29.4053 17.7592 30.4808 16.6412 31.2324C16.1906 31.5362 15.7317 31.7401 15.2644 31.8441C14.7972 31.948 14.2381 32 13.5873 32H11.4346V24ZM13.0616 25.3433V30.6567H13.5623C14.4133 30.6567 15.0934 30.4808 15.6023 30.1289C15.9278 29.905 16.1822 29.6052 16.3658 29.2294C16.5494 28.8456 16.6412 28.4378 16.6412 28.006C16.6412 27.5822 16.5494 27.1824 16.3658 26.8066C16.1906 26.4228 15.9403 26.1109 15.6149 25.8711C15.1226 25.5192 14.4384 25.3433 13.5623 25.3433H13.0616Z" fill="white"/>
          <path d="M5 24H7.42807C7.94539 24 8.36676 24.044 8.69217 24.1319C9.02593 24.2119 9.33883 24.3718 9.63086 24.6117C10.1982 25.0915 10.4819 25.7351 10.4819 26.5427C10.4819 27.2944 10.2525 27.8901 9.79357 28.3298C9.30128 28.8016 8.59622 29.0375 7.67839 29.0375H6.62706V32H5V24ZM6.62706 25.3433V27.6942H7.2904C7.766 27.6942 8.12062 27.6102 8.35425 27.4423C8.65463 27.2184 8.80482 26.9065 8.80482 26.5067C8.80482 26.1149 8.65045 25.8111 8.34173 25.5952C8.09141 25.4273 7.70759 25.3433 7.19027 25.3433H6.62706Z" fill="white"/>
        </svg>
        <div class=" whitespace-nowrap ml-3 flex flex-col justify-start font-futura items-start">
          <p class="max-w-[180px] leading-6 text-[18px] truncate">
            {{ getAttachName(i.headers['content-disposition']) }}
          </p>
          <p class="leading-6 text-[18px]">
            {{ getAttachSize(i.body) }}
          </p>
        </div>
      </div>
      <button
        class="mt-2 md:mt-0 ml-2 self-end leading-6 text-[18px] text-[#2979FF]"
        @click="saveAttach(index)"
      >
        Download
      </button>
    </div>
  </div>
  <EmptyContentComponent
    v-else
    type="Attachments"
  />
</template>
<script>

import EmptyContentComponent from '@/components/include/EmptyContentComponent.vue'
import PrivateMessagesService from '@/http/services/private/privateMessages.service'

export default {
  name: 'AttachmentsTab',
  components: { EmptyContentComponent },
  props: {
    data: {
      type: Array,
      default: () => []
    },
    inbox: {
      type: String,
      default: ''
    },
    messageId: {
      type: String,
      default: ''
    }
  },
  methods: {
    saveAttach (attachIndex) {
      if (!this.inbox || !this.messageId) return

      PrivateMessagesService.saveAttach(this.inbox, this.messageId, attachIndex)
    },
    getAttachType (data) {
      let type = ''
      if (data.toLowerCase().includes('image/jpeg')) {
        type = 'jpg'
      } else if (data.toLowerCase().includes('officedocument')) {
        type = 'docx'
      } else if (data.toLowerCase().includes('application/pdf')) {
        type = 'pdf'
      } else {
        type = 'docx'
      }

      return type
    },
    getAttachName (data) {
      const index = []
      data.split('').forEach((i, ind) => i === '=' ? index.push(ind) : null)

      return index.length ? data.slice(index[0] + 2, data.length - 1) : ''
    },
    getAttachSize (data) {
      const length = data.length
      const y = data.slice(-2) === '=='
        ? 2
        : 1
      const size = (length * (3 / 4)) - y

      return this.readableBytes(size)
    },
    readableBytes (bytes, decimals = 2) {
      if (!+bytes) return '0 Bytes'

      const k = 1024
      const dm = decimals < 0 ? 0 : decimals
      const sizes = ['Bytes', 'kb', 'mb', 'gb', 'tb', 'pb', 'eb', 'zb', 'yb']

      const i = Math.floor(Math.log(bytes) / Math.log(k))

      return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
    }
  }
}
</script>
