<template>
  <div class="max-h-[calc(100vh_-_250px)] 2xl:max-h-[calc(100vh_-_278px)] mx-auto md:ml-2 my-4 pr-2 overflow-x-auto">
    <table class="min-w-full">
      <tableHead
        :isCheckedAll="isCheckedAll"
        @set-is-checked-all="allCheck"
        @update-messages-list="$emit('update-messages-list')"
      />
      <tbody>
      <tableRow
        v-for="person in tableData" :key="person.id"
        :rowData="person"
        @row-check="rowCheck"
        @click.stop="redirectToMessage(person.id)"
      />
      </tbody>
    </table>
  </div>
</template>
<script>
import tableRow from '@/components/public/mailsList/table/TableRow.vue'
import tableHead from '@/components/public/mailsList/table/TableHead.vue'

export default {
  name: 'InboxMailsTableComponent',
  components: { tableHead, tableRow },
  props: {
    tableData: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      isCheckedAll: false
    }
  },
  watch: {
    tableData () {
      this.isCheckedAll = false
    }
  },
  methods: {
    allCheck () {
      this.isCheckedAll = !this.isCheckedAll
      this.$emit('check-all', this.isCheckedAll)
    },
    rowCheck (id) {
      this.$emit('check-item', id)
    },
    redirectToMessage (id) {
      const isPublic = this.$route.name === 'PublicMailInbox'

      this.$router.push(
        {
          name: isPublic ? 'PublicMail' : 'Mail',
          query: {
            msgid: id,
            inbox: this.$route.query?.inbox ? this.$route.query?.inbox : ''
          }
        })
    }
  }
}
</script>
