<template>
  <PrivateMailLayout>
    <LoaderComponent v-if="isLoading"/>
    <PrivateMailsListComponent
      v-else
      :mails-list="data"
      :mail-domains="mailDomains"
      @update-data="updateData"
    />
  </PrivateMailLayout>
</template>

<script>

import PrivateMailLayout from '@/layouts/PrivateMailLayout.vue'
import PrivateMailsListComponent from '@/components/private/mailsList/PrivateMailsListComponent.vue'
import LoaderComponent from '@/components/include/LoaderComponent.vue'
import PrivateMessagesService from '@/http/services/private/privateMessages.service'
import { connectWebSocket, createRoom, disconnectWebSocket } from '@/http/services/private/privateWebsocket.service'
export default {
  name: 'PrivateMailInboxView',
  components: {
    LoaderComponent,
    PrivateMailLayout,
    PrivateMailsListComponent
  },
  data () {
    return {
      intervalId: null,
      isLoading: false,
      data: [],
      mailDomains: [],
      roomName: ''
    }
  },
  watch: {
    data () {
      if (this.data) this.isLoading = false
    }
  },
  async created () {
    this.isLoading = true
    this.roomName = this.$route.query?.inbox

    await this.getDomains()

    await this.connectAndCreateRoom()

    this.isLoading = false
  },
  async beforeUnmount () {
    disconnectWebSocket()
  },
  methods: {
    async connectAndCreateRoom () {
      await connectWebSocket(this.handleMessage)
      await createRoom(this.roomName)
    },
    handleMessage (data) {
      if (data.channel === 'initial_msgs') {
        this.data = data.msgs.length ? data.msgs.reverse() : []
      } else if (data.channel === 'msg') this.data.unshift(data)
      else if (data.channel === 'error') this.data = []
    },
    updateData (val) {
      this.data = val
    },
    async getDomains () {
      const { domains } = await PrivateMessagesService.fetchDomains()

      domains?.forEach(d => {
        if (d.enabled) this.mailDomains.push(d.name)
      })
      if (!this.mailDomains.length) this.mailDomains.push('testinator.com')

      PrivateMessagesService.setDomain(this.mailDomains[0])
    }
  }
}
</script>
