<template>
  <div class="w-full mt-4 flex flex-col justify-start items-center">
    <form class="w-full" @submit.prevent="submitForm">
      <div class="w-full h-[20px] mb-1 text-sm text-red-500 text-center">
        {{error ? error : ''}}
      </div>
      <input
        type="email"
        name="email"
        placeholder="Email"
        required
        class="w-full mb-6 py-[6px] px-3 rounded text-[14px] border border-[#c8c8c8] placeholder-color-[#b7bbc0] bg-white outline-none"
      >
      <div class="flex flex-wrap justify-around items-center">
        <button
          type="submit"
          class="mx-1 px-4 py-2 rounded border border-[#c8c8c8] bg-white hover:bg-[#e6e6e6] text-[12px] text-[#333]"
        >
          Submit
        </button>
        <router-link
          class="mx-1 text-[#5a738e] text-sm"
          :to="{ name: 'EmailLogin' }"
        >
          Login with Email
        </router-link>
      </div>
    </form>
    <div class="my-4 w-full h-[1px] bg-[#D8D8D8]"></div>
  </div>
</template>

<script>

import AuthService from '@/http/services/auth.service'

export default {
  name: 'SsoLoginComponent',
  data () {
    return {
      error: ''
    }
  },
  methods: {
    async submitForm (e) {
      const email = e.target.elements.email.value
      const formData = new FormData(e.target)

      const queryData = {
        email
      }

      const res = await AuthService.loginWithSSO(formData, queryData)
      if (res.error) {
        this.error = res.error
      } else {
        this.$router.push({ name: 'PrivateMailInbox' })
      }
    }
  }
}
</script>
<style scoped>
input {
  box-shadow: 0 1px 0 #fff, 0 -2px 5px rgba(0, 0, 0, .08) inset;
}
</style>
