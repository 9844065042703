<template>
  <div class="w-full h-full pt-4 xl:pt-0 md:pb-2 bg-white rounded-xl font-futura">
    <div
      class="w-full px-2 md:px-4 flex flex-wrap flex-col lg:flex-row justify-center md:justify-between items-center">
      <div class="md:ml-2 flex flex-col md:flex-row justify-center items-center">
        <button @click="redirect">
          <p class="text-[18px] text-[#262A41] align-middle">Private Team Inbox</p>
        </button>
        <svg
          class="mx-2 my-2 md:my-0"
          width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M0.500098 8.22883L3.73343 4.9955L0.500098 1.76216C0.175098 1.43716 0.175098 0.912164 0.500098 0.587164C0.825098 0.262164 1.3501 0.262164 1.6751 0.587164L5.5001 4.41216C5.8251 4.73716 5.8251 5.26216 5.5001 5.58716L1.6751 9.41216C1.3501 9.73716 0.825098 9.73716 0.500098 9.41216C0.183431 9.08716 0.175098 8.55383 0.500098 8.22883Z" fill="#262A41"/>
        </svg>
        <p class="text-[18px] text-[#262A41] align-middle text-center">
          {{mailData.subject ? mailData.subject : '-'}}
        </p>
      </div>
      <div
        class="flex justify-center items-center"
      >
          <ForwardEmailComponent
            isOnceMessage
            @forward-emails="forwardEmail"
          />
          <div
            class="h-[22px] mx-4 border-x border-[#D1D1D6]"></div>
        <DeleteEmailComponent
          isOnceMessage
          @delete-checked-email="deleteEmail"
        />
      </div>
    </div>
    <PrivateMailInfoComponent
      :mailData="mailData"
    />
    <TabsComponent
      @set-current-tab="setCurrentTab"
    />
    <TextTab
      v-if="currentTab === 'TEXT'"
      :data="textData"
    />
    <HtmlTab
      v-if="currentTab === 'HTML'"
      :data="htmlData"
    />
    <JsonTab
      v-if="currentTab === 'JSON'"
      :data="jsonData"
    />
    <RawTab
      v-if="currentTab === 'RAW'"
      :data="mailData"
    />
    <LinksTab
      v-if="currentTab === 'LINKS'"
      :data="linksData"
    />
    <SmtpLogsTab
      v-if="currentTab === 'SMTP_LOGS'"
      :data="smtpLogsData"
    />
    <AttachmentsTab
      v-if="currentTab === 'ATTACHMENTS'"
      :inbox="mailData.to"
      :messageId="mailData.id"
      :data="attachmentsData"
    />
  </div>
</template>

<script>

import DeleteEmailComponent from '@/components/include/DeleteEmailComponent.vue'
import TabsComponent from '@/components/include/tabs/TabsComponent.vue'
import TextTab from '@/components/include/tabs/TextTab.vue'
import HtmlTab from '@/components/include/tabs/HtmlTab.vue'
import SmtpLogsTab from '@/components/include/tabs/SmtpLogsTab.vue'
import LinksTab from '@/components/include/tabs/LinksTab.vue'
import JsonTab from '@/components/include/tabs/JsonTab.vue'
import RawTab from '@/components/include/tabs/RawTab.vue'
import AttachmentsTab from '@/components/include/tabs/AttachmentsTab.vue'
import ForwardEmailComponent from '@/components/include/ForwardEmailComponent.vue'
import PrivateMailInfoComponent from '@/components/private/mailPage/PrivateMailInfoComponent.vue'
import PrivateMessagesService from '@/http/services/private/privateMessages.service'
export default {
  name: 'PrivateMailComponent',
  components: {
    PrivateMailInfoComponent,
    ForwardEmailComponent,
    LinksTab,
    DeleteEmailComponent,
    TabsComponent,
    TextTab,
    HtmlTab,
    SmtpLogsTab,
    JsonTab,
    RawTab,
    AttachmentsTab
  },
  props: {
    mailData: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      raw: null,
      currentTab: 'TEXT'
    }
  },
  computed: {
    textData () {
      if (!this.mailData.parts || !this.mailData.parts.length) return ''
      const content = this.mailData.parts.find(i => i.headers['content-type']?.toLowerCase().includes('text/plain'))
      return content ? content.body : ''
    },
    htmlData () {
      if (!this.mailData.parts || !this.mailData.parts.length) return ''
      const content = this.mailData.parts.find(i => i.headers['content-type']?.toLowerCase().includes('html'))
      return content ? content.body : ''
    },
    linksData () {
      if (!this.mailData.clickablelinks || !this.mailData.clickablelinks.length) return []
      return this.mailData.clickablelinks
    },
    smtpLogsData () {
      return this.mailData.smtp_log ? this.mailData.smtp_log : ''
    },
    jsonData () {
      return JSON.stringify(this.mailData, null, 2)
    },
    attachmentsData () {
      if (!this.mailData.parts || !this.mailData.parts.length) return []

      const filteredByContent = this.mailData.parts.filter(
        i => Object.keys(i.headers)
          .find(k => k === 'content-disposition')
      )
      return filteredByContent.filter(i =>
        i.headers['content-disposition']
          .toLowerCase()
          .includes('attachment')
      )
    }
  },
  methods: {
    redirect () {
      this.$router.push(
        {
          name: 'PrivateMailInbox',
          query: {
            inbox: this.$route.query?.inbox ? this.$route.query?.inbox : ''
          }
        })
    },
    setCurrentTab (tab) {
      this.currentTab = tab
    },
    async forwardEmail () {
      await PrivateMessagesService.forwardPrivateEmails([this.mailData])
    },
    async deleteEmail () {
      await PrivateMessagesService.deletePrivateMessages([this.mailData.id])
      setTimeout(() => {
        this.$router.push({ name: 'PrivateMailInbox' })
      }, 1000)
    }
  }
}
</script>
