<template>
  <div class="w-full h-full pt-4 md:pb-2 bg-white rounded-xl flex justify-center items-center">
    <half-circle-spinner :animation-duration="1000" :size="60" color="#262A41" />
  </div>
</template>

<script>

import { HalfCircleSpinner } from 'epic-spinners'
export default {
  name: 'LoaderComponent',
  components: {
    HalfCircleSpinner
  }
}
</script>
