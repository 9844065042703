<template>
  <div class="mt-2 flex flex-col md:flex-row flex-wrap justify-between items-start md:items-center text-futura">
<!--  search  -->
<!--    TODO: This has been saved until it can be used. Now backend not ready.-->
<!--    <div class="relative mx-2 mb-2 xl:mb-0">-->
<!--      <svg-->
<!--        class="absolute left-3 top-3"-->
<!--        width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"-->
<!--      >-->
<!--        <path d="M12.7549 11.255H11.9649L11.6849 10.985C12.6649 9.845 13.2549 8.365 13.2549 6.755C13.2549 3.165 10.3449 0.255005 6.75488 0.255005C3.16488 0.255005 0.254883 3.165 0.254883 6.755C0.254883 10.345 3.16488 13.255 6.75488 13.255C8.36488 13.255 9.84488 12.665 10.9849 11.685L11.2549 11.965V12.755L16.2549 17.745L17.7449 16.255L12.7549 11.255ZM6.75488 11.255C4.26488 11.255 2.25488 9.245 2.25488 6.755C2.25488 4.26501 4.26488 2.255 6.75488 2.255C9.24488 2.255 11.2549 4.26501 11.2549 6.755C11.2549 9.245 9.24488 11.255 6.75488 11.255Z" fill="#9294A0"/>-->
<!--      </svg>-->
<!--      <input-->
<!--        type="text"-->
<!--        class="w-full sm:w-[257px] h-[40px] py-2 pl-10 border-2 rounded-lg focus:outline-none placeholder:text-[#262A41]"-->
<!--        placeholder="Search"-->
<!--        v-model="search"-->
<!--      />-->
<!--    </div>-->
<!--  mail selects for laptop view  -->
    <div class="flex flex-col md:flex-row flex-wrap justify-start items-center">
      <div class="hidden sm:flex mx-2 justify-center items-center mb-2 xl:mb-0">
<!--   mail input     -->
        <input
          placeholder="All the inboxes"
          class="w-[220px] h-[40px] py-1 pl-3 pr-8 border-2 rounded-l-lg appearance-none focus:outline-none placeholder:text-[#262A41]"
          v-model="inboxName"
          @change="changeInboxes($event)"
        />
        <div class="w-[46px] h-[40px] flex justify-center items-center border-t-2 border-b-2">
          <svg
            width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M9.59819 0.689852C4.52319 0.331518 0.331518 4.52319 0.689852 9.59819C0.989852 14.0065 4.83985 17.3315 9.25652 17.3315H12.3315C12.7899 17.3315 13.1649 16.9565 13.1649 16.4982C13.1649 16.0399 12.7899 15.6649 12.3315 15.6649H9.27318C6.16485 15.6649 3.31485 13.6482 2.53985 10.6399C1.29819 5.80652 5.79818 1.29819 10.6315 2.54819C13.6482 3.31485 15.6649 6.16485 15.6649 9.27318V10.1899C15.6649 10.8482 15.0732 11.4982 14.4149 11.4982C13.7565 11.4982 13.1649 10.8482 13.1649 10.1899V9.14819C13.1649 7.05652 11.6815 5.17319 9.61485 4.88152C6.78152 4.47319 4.38985 6.92319 4.89819 9.77319C5.18152 11.3649 6.42319 12.6815 7.99819 13.0565C9.53152 13.4149 10.9899 12.9232 11.9482 11.9482C12.6899 12.9649 14.1732 13.4982 15.5315 12.9565C16.6482 12.5149 17.3315 11.3732 17.3315 10.1732V9.26485C17.3315 4.83985 14.0065 0.989852 9.59819 0.689852ZM8.99819 11.4982C7.61485 11.4982 6.49819 10.3815 6.49819 8.99819C6.49819 7.61485 7.61485 6.49819 8.99819 6.49819C10.3815 6.49819 11.4982 7.61485 11.4982 8.99819C11.4982 10.3815 10.3815 11.4982 8.99819 11.4982Z" fill="#2979FF"/>
          </svg>
        </div>
<!--   domain select  -->
        <div class="relative">
          <select @change="changeDomain($event)" class="w-[220px] h-[40px] py-1 px-3 border-2 rounded-r-lg appearance-none focus:outline-none placeholder:text-[#262A41]] cursor-pointer">
            <option v-for="(option, index) of mailDomains" :key="index">{{ option }}</option>
          </select>
          <svg
            class="absolute right-4 top-4"
            width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M1.17739 2.36173L4.18322 5.18155C4.63584 5.60615 5.36698 5.60615 5.8196 5.18155L8.82543 2.36173C9.55658 1.67583 9.03433 0.5 8.00144 0.5H1.98978C0.956885 0.5 0.446242 1.67583 1.17739 2.36173Z" fill="#9294A0"/>
          </svg>
        </div>
      </div>
<!--   mail selects for mobile view   -->
      <div class="flex flex-col justify-start items-center sm:hidden mx-2 mb-4">
        <input
          placeholder="All the inboxes"
          class="w-[220px] h-[40px] py-1 pl-3 pr-8 mb-2 border-2 rounded-lg appearance-none focus:outline-none placeholder:text-[#262A41]"
          v-model="inboxName"
          @change="changeInboxes($event)"
        />
        <select
          name="domain"
          @change="changeDomain($event)"
          class="w-full max-w-[224px] h-[40px] py-1 px-3 border-2 rounded-lg focus:outline-none placeholder:text-[#262A41]] cursor-pointer"
        >
          <option
            v-for="(option, index) of mailDomains" :key="index"
            :value="option"
            class="m-4"
          >
            {{ option }}
          </option>
        </select>
      </div>
<!--   play/stop button   -->
      <button
        class="w-[46px] h-[40px] mb-2 xl:mb-0 mx-2 flex justify-center items-center border-2 rounded-lg"
        @click="setWsPause"
      >
        <svg
          v-if="isWebsocketsPaused"
          width="22" height="16" viewBox="-3 0 28 28" version="1.1" xmlns="http://www.w3.org/2000/svg"
        >
          <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g transform="translate(-419.000000, -571.000000)" fill="#2979FF">
              <path d="M440.415,583.554 L421.418,571.311 C420.291,570.704 419,570.767 419,572.946 L419,597.054 C419,599.046 420.385,599.36 421.418,598.689 L440.415,586.446 C441.197,585.647 441.197,584.353 440.415,583.554" id="play">
              </path>
            </g>
          </g>
        </svg>
        <svg
          v-else
          width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M0 14H4V0H0V14ZM8 0V14H12V0H8Z" fill="#2979FF"/>
        </svg>
      </button>
    </div>
  </div>
</template>

<script>

import { createRoom, setWsPause, setDomain } from '@/http/services/private/privateWebsocket.service'
import PrivateMessagesService from '@/http/services/private/privateMessages.service'

export default {
  name: 'PrivateControllersComponent',
  props: {
    mailDomains: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      // search: '',
      inboxName: '',
      isWebsocketsPaused: false
    }
  },
  watch: {
    async $route () {
      this.inboxName = this.$route.query?.inbox
    }
  },
  methods: {
    changeDomain (event) {
      setDomain(event.target.value)
    },
    async changeInboxes (event) {
      this.inboxName = event.target.value ? event.target.value : ''

      await createRoom(this.inboxName)
      this.updateRoute(this.inboxName)
      PrivateMessagesService.setInbox(this.inboxName)
    },
    setWsPause () {
      this.isWebsocketsPaused = !this.isWebsocketsPaused

      setWsPause(this.isWebsocketsPaused)
      if (!this.isWebsocketsPaused) createRoom(this.inboxName)
    },
    updateRoute (val) {
      this.$router.replace({
        query: {
          ...this.$route.query,
          inbox: val
        }
      })
    }
  }
}
</script>
