<template>
  <div class="grid grid-cols-12 grid-rows-12 min-h-screen bg-gradient-to-b from-[#2A2F48] to-[#141622] tracking-wide">
    <HeaderComponent
      :isPublic="true"
      @toggle-navbar="toggleNavbar"
    />
    <div class="col-span-12 row-span-12 flex">
      <NavbarMobileScreenComponent
        :is-navbar-open="navbarState"
        @toggle-navbar="toggleNavbar"
      />
      <NavbarComponent
        :isPublic="true"
      />
      <main class="w-full lg:w-[calc(100vw_-_250px)] h-full px-3 pb-2 md:px-4 lg:pl-0 lg:pr-0">
        <slot></slot>
      </main>
    </div>
  </div>
</template>
<script>

import HeaderComponent from '@/components/include/HeaderComponent.vue'
import NavbarComponent from '@/components/include/navbar/laptopScreen/NavbarComponent.vue'
import NavbarMobileScreenComponent from '@/components/include/navbar/mobileScreen/NavbarMobileScreenComponent.vue'

export default {
  name: 'PublicMailLayout',
  components: {
    HeaderComponent,
    NavbarComponent,
    NavbarMobileScreenComponent
  },
  data () {
    return {
      navbarState: false
    }
  },
  methods: {
    toggleNavbar () {
      this.navbarState = !this.navbarState
    }
  }
}
</script>
