<template>
  <p
    v-if="data"
    class="h-[calc(100vh_-_325px)] xl:h-[calc(100vh_-_335px)] 2xl:h-[calc(100vh_-_365px)] p-8 xl:mx-2 mt-2 overflow-auto whitespace-pre-line"
    v-html="highlightLinksFromText"
  ></p>
  <EmptyContentComponent
    v-else
    type="TEXT"
  />
</template>
<script>

import EmptyContentComponent from '@/components/include/EmptyContentComponent.vue'

export default {
  name: 'TextTab',
  components: {
    EmptyContentComponent
  },
  props: {
    data: {
      type: String,
      default: ''
    }
  },
  computed: {
    highlightLinksFromText () {
      if (!this.data) return ''

      let body = this.data
      body = body.replaceAll('<', '&lt;')
      body = body.replaceAll('>', '&gt;')

      body = body.replaceAll('\r\n', '<br>')
      body = body.replaceAll('\n\r', '<br>')
      body = body.replaceAll('\r', 'br>')
      body = body.replaceAll('\n', '<br>')
      body = body.replaceAll('\t', '  ')
      // eslint-disable-next-line
      const exp = /((href|src)=["']|)(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig
      return body.replace(exp, function () {
        return arguments[1]
          ? arguments[0]
          // eslint-disable-next-line
          : "<a class='text-[#2979FF]' href=\"" + arguments[3] + "\" target='_other' rel=nofollow>" + arguments[3] + "</a>"
      })
    }
  }
}
</script>
